













import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { EAccountNotification, EOnbordingStep, ERole } from '@/api/schema';

const NotificationPopupWelcome = (): any =>
  import('@/components/notification-popup/NotificationPopupWelcome.vue');

const NotificationPopupSuccessTransaction = (): any =>
  import(
    '@/components/notification-popup/NotificationPopupSuccessTransaction.vue'
  );

const NotificationPopupSuccessOnboarding = (): any =>
  import(
    '@/components/notification-popup/NotificationPopupSuccessOnboarding.vue'
  );

const notificationType = Object.freeze({
  [EAccountNotification.WELCOME]: 'welcome',
  [EAccountNotification.SUCCESS_TRAFFIC]: 'success-transaction',
  [EAccountNotification.SUCCESS_ONBOARDING]: 'success-onboarding'
});

let TIMEOUT;

export default {
  name: 'NotificationPopup',
  components: {
    NotificationPopupWelcome,
    NotificationPopupSuccessTransaction,
    NotificationPopupSuccessOnboarding
  },
  data(): any {
    return {
      current: null,
      modal: false,
      popupSize: '664px',
      queue: [],
      currentOperator: null,
      isLoading: true
    };
  },
  computed: {
    ...mapState('Operator', ['storage', 'storageError']),
    ...mapState('Onboarding', ['selectedOperator', 'loading']),
    ...mapGetters('Onboarding', [
      'operatorId',
      'operatorProgress',
      'successOnboardingTestProgress'
    ]),

    notification(): EAccountNotification[] {
      return this.storage.notification;
    },
    hasUpdates(): boolean {
      return (
        !this.loading.progress && this.isCurrentOperator && !this.isLoading
      );
    },
    successTest(): boolean {
      if (!this.selectedOperator.test || !this.hasUpdates) return false;

      return this.successOnboardingTestProgress;
    },
    hasProdTransactions(): boolean {
      if (this.selectedOperator.test || !this.hasUpdates) return false;

      return this.operatorProgress[EOnbordingStep.trafficProgress];
    },
    currentComponent(): string | null {
      const type = notificationType[this.current];

      if (!type) return null;

      return `notification-popup-${type}`;
    },
    isCurrentOperator(): boolean {
      return this.currentOperator === this.operatorId;
    }
  },
  watch: {
    current: 'watchType',
    modal: 'watchModal',
    hasProdTransactions(val: boolean): void {
      if (!val) return;

      this.setCurrent(EAccountNotification.SUCCESS_TRAFFIC);
    },
    successTest(val: boolean): void {
      if (!val) return;

      this.setCurrent(EAccountNotification.SUCCESS_ONBOARDING);
    },
    operatorId: {
      handler: 'handleOperatorChange',
      immediate: true
    },
    '$route.name': {
      handler(val: string): void {
        if (!this.$role.is(['account'])) return;

        if (
          val === 'billing-info' &&
          !this.notification.includes(EAccountNotification.INVITE_FINANCE)
        ) {
          this.saveNotificationState(EAccountNotification.INVITE_FINANCE);
          setTimeout(
            () =>
              this.setInviteUserDialogOptions({
                show: true,
                type: ERole.ROLE_OPERATOR_FINANCE,
                title: '',
                hideTitle: true,
                description:
                  'Invite Financial Manager to manage your subscription'
              }),
            300
          );
        }
      }
    }
  },
  mounted(): void {
    if (this.storageError) return;

    this.getAccountStorage()
      .then(() => this.setCurrent(this.getInitialType()))
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('Operator', ['getAccountStorage', 'saveNotificationState']),
    ...mapMutations('Onboarding', ['setInviteUserDialogOptions']),
    watchType(curr: EAccountNotification, prev: EAccountNotification): void {
      if (curr) {
        if (prev) {
          this.modal = false;
        }

        this.$nextTick(() => {
          this.popupSize =
            curr === EAccountNotification.WELCOME ? '814px' : '664px';
          this.modal = true;
        });
      } else {
        this.modal = false;
      }
    },
    watchModal(isOpen: boolean): void {
      if (isOpen) return;

      const setCurrent = (): Promise<void> =>
        new Promise((resolve) =>
          setTimeout(() => {
            this.setCurrent();
            resolve();
          }, 50)
        );

      setCurrent().then(() => {
        if (this.queue.length) {
          this.setCurrent(this.queue[0]);
          this.queue.splice(0, 1);
        }
      });
    },
    setCurrent(type: EAccountNotification | null = null): void {
      if (this.storageError) return;

      if (type && !this.notification.includes(type)) {
        if (this.modal && this.current) {
          this.queue.push(type);

          return;
        }

        this.current = type;
        this.saveNotificationState(this.current);
      } else {
        this.current = null;
      }
    },
    handleNext(notificationType: EAccountNotification): void {
      this.setCurrent(notificationType);
    },
    handleClose(): void {
      this.modal = false;
    },
    getInitialType(): EAccountNotification {
      if (this.hasProdTransactions || this.successOnboardingTestProgress) {
        return this.successOnboardingTestProgress
          ? EAccountNotification.SUCCESS_ONBOARDING
          : EAccountNotification.SUCCESS_TRAFFIC;
      }

      return EAccountNotification.WELCOME;
    },
    handleOperatorChange(newId: string, oldId: string): void {
      if (TIMEOUT) {
        clearTimeout(TIMEOUT);
      }
      TIMEOUT = setTimeout(
        () => {
          this.currentOperator = newId;
        },
        oldId ? 3000 : 0
      );
    }
  }
};
